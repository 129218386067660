import { last } from 'fp-ts/lib/Array';
import { constant, pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/Option';

import { lastIndexOf } from '../utils/array.utils';
import { isUserMessage } from '../utils/renderer-utils/renderer.utils';
import { Nullable } from '../utils/types.utils';
import { LocalizationModel } from './languages.model';

type Gender = 'f' | 'm';

const UI_MESSAGE_TYPE = [
	'text',
	'image',
	'video',
	'audio',
	'message',
	'command',
	'messageStatus',
	'quickResponses',
	'carousel',
];

const RESPONSE_TYPES_CUSTOM_COMPONENTS = ['date', 'phone', 'mobile', 'email', 'weight', 'otp'];

export type CardResponseType = 'flowStep' | 'quickResponse' | 'webUrlExternalWithResponse' | 'webUrlExternal';
export type QuickResponseType = 'text' | 'webUrlExternalWithResponse' | 'webUrlExternal' | 'location';
export type QuickResponseButtonType = 'button' | 'multi';
export type ResponseType =
	| ''
	| 'string'
	| 'date'
	| 'location'
	| 'phone'
	| 'mobile'
	| 'email'
	| 'data'
	| 'age'
	| 'temperature'
	| 'number'
	| 'complaints'
	| 'image'
	| 'intentbutton'
	| 'weight'
	| 'otp';

interface ParsingInfoSuccess {
	status: 'success';
}
interface ParsingInfoFailure<T> {
	status: 'failure';
	originalMessage: T;
	error: unknown;
}

export interface ParsedData<T> {
	data: Nullable<T>;
	parsingInfo: ParsingInfoSuccess | ParsingInfoFailure<T>;
}
export interface NotNullableParsedData<T> {
	data: T;
	parsingInfo: ParsingInfoSuccess | ParsingInfoFailure<T>;
}

export interface SpecialAction {
	action: 'setlocale';
	value: LocalizationModel;
}

export interface DefaultMetadataStructure {
	caseId: string;
	data: {
		stepName?: string;
		flowId?: string;
	};
}

export interface AppointmentSchedulingMetaDataData {
	changeProviderFlowStep: string;
	providerName: string;
	scope: 'OPEN_SCHEDULING' | 'DIRECT_SCHEDULING';
	flowId: string;
	stepName: string;
	criteria: Record<string, unknown>;
	calendarSearchTimeMonths?: number;
	templates: {
		schedulerFootnote?: string;
		changeProvider?: string;
	};
}
export interface AppointmentSchedulingMetaData {
	status: 'showAppointmentScheduling';
	caseId: string;
	data: AppointmentSchedulingMetaDataData;
}
export interface UserSurveyMetaData extends DefaultMetadataStructure {
	status: 'showUserSurvey';
}
export interface AppointmentSchedulingSummaryMetaDataDataLocation {
	city?: string;
	name?: string;
	stateCode?: string;
	street?: string;
	zip?: string;
}

export interface AppointmentSchedulingSummaryMetaDataDataValues {
	appointmentDate: string;
	appointmentTime: string;
}
export interface AppointmentSchedulingSummaryMetaDataData {
	appointmentValues: AppointmentSchedulingSummaryMetaDataDataValues;
	contact?: Nullable<string>;
	location?: Nullable<AppointmentSchedulingSummaryMetaDataDataLocation>;
	makeChangeFlowStep: string;
	cancelAppointmentFlowStep?: string;
	providerName: string;
	scope: string;
	flowId: string;
	stepName: string;
	templates: {
		additionalInformation?: string;
	};
}
export interface BasicMetaData {
	caseId: string;
	status: string;
}

export interface AppointmentSchedulingSummaryMetaData {
	status: 'showAppointmentSummary';
	caseId: string;
	data: AppointmentSchedulingSummaryMetaDataData;
}

interface LiveChatInitMetaDataData {
	title: string;
	payload: string;
	description?: string;
}
export interface LiveChatInitMetaData {
	status: 'showLiveChatInit';
	caseId: string;
	data: LiveChatInitMetaDataData;
}

export interface LastLiveChatMessageMetaData extends DefaultMetadataStructure {
	status: 'lastLiveChatFeedbackMessage';
}

export interface AppointmentManagementMetaData extends DefaultMetadataStructure {
	status: 'showAppointmentManagement';
	data: {
		stepName?: string;
		flowId?: string;
		emptySlotsStep: string;
	};
}
export interface LLMTooltipConfig {
	enabled: boolean;
	templates?: {
		label?: string;
		content?: string;
	};
}

export interface LLMSearchResult {
	preview: string;
	text: string;
	title: string;
	url: string;
}
export interface LLMResultMetaData extends DefaultMetadataStructure {
	status: 'llmResult';
	data: {
		searchResults: LLMSearchResult[];
		content: string;
		llmConversationId: string;
		llmMessageId?: string;
		tooltipConfig: LLMTooltipConfig;
		stepName?: string;
		flowId?: string;
	};
}

// Doctor search
type DoctorSearchFilterType = 'check' | 'option' | 'select' | 'list';

export interface DoctorSearchFilterItem {
	label: string;
	value: string;
	selected?: boolean;
}

export interface DoctorSearchFilter {
	name: string;
	type: DoctorSearchFilterType;
	label: string;
	multi?: boolean;
	required?: boolean;
	group?: string;
	value?: string;
	items?: DoctorSearchFilterItem[];
	placeholder?: string;
}

export interface DoctorSearchOption {
	hideAcceptingNewPatients: boolean;
	hideAvailability: boolean;
}
export interface DoctorSearchMetaData extends DefaultMetadataStructure {
	status: 'showDoctorSearch';
	data: {
		stepName?: string;
		flowId?: string;
		query: string;
		sorting: Nullable<DoctorSearchFilter>;
		filters: DoctorSearchFilter[];
		searchContextLabel: Nullable<string>;
		emptySlotsStep?: string;
		noDoctorsStep?: string;
		options: Nullable<Partial<DoctorSearchOption>>;
	};
}
export interface PasswordResetValidation {
	message: string;
	expression: string;
	isGeneral: boolean;
}
export interface PasswordResetMetaData extends DefaultMetadataStructure {
	status: 'showPasswordReset';
	data: {
		constraintsMessage: string;
		validations: PasswordResetValidation[];
		flowId?: string;
	};
}

// Live chat

export interface MessageWithDoctorInfo extends TextMessage {
	doctorId: string;
	doctorInfo: LiveChatDoctorInfo;
}

export type LiveChatMessage = Message & {
	timeStamp: number;
};

export type LiveChatCommand = 'startTyping' | 'endTyping' | 'endLiveChat' | 'leaveQueue' | 'retryMessages';

export interface LiveChatCommandMessage {
	originalText: '👋';
	type: 'command';
	value: LiveChatCommand;
}
interface LiveChatUserData {
	firstName: string;
	lastName: string;
	email: string;
}
export interface LiveChatStartMetadata {
	status: 'startLiveChat';
	caseId: string;
	data: {
		queue: string;
		userData: Partial<LiveChatUserData>;
	};
}
interface LiveChatEndMetadata {
	status: 'endLiveChat';
}
interface LiveChatAgentJoinedMetadata {
	status: 'agentJoined';
}
interface LiveChatAgentLeftMetadata {
	status: 'agentLeft';
}
export interface LiveChatAgentStartTypingMetadata {
	status: 'startTyping';
}

export interface LiveChatAgentStartTypingTimeoutMetadata extends LiveChatAgentStartTypingMetadata {
	data: {
		timeout: number;
	};
}
interface LiveChatAgentEndTypingMetadata {
	status: 'endTyping';
}

export interface LiveChatDoctorInfo {
	displayName: string;
	firstName?: string;
	lastName?: string;
	initials?: string;
	isLiveChatAgent?: boolean;
}

export interface ButtonConfig {
	emojiType: string;
}

export interface SatisfactionSurveyMetadataData {
	buttons: ButtonConfig[];
}
export interface SatisfactionSurveyMetadata {
	caseId: string;
	data: SatisfactionSurveyMetadataData;
	status: 'showSatisfactionSurvey';
}

interface CustomMetadata extends Record<string, unknown> {
	status: 'custom';
}

export interface PostMessageMetadata {
	status: 'postMessage';
	data: Record<string, unknown>;
}

export type MetaData =
	| AppointmentSchedulingMetaData
	| AppointmentSchedulingSummaryMetaData
	| UserSurveyMetaData
	| BasicMetaData
	| LiveChatStartMetadata
	| LiveChatEndMetadata
	| LiveChatAgentJoinedMetadata
	| LiveChatAgentLeftMetadata
	| LiveChatAgentStartTypingMetadata
	| LiveChatAgentStartTypingTimeoutMetadata
	| LiveChatAgentEndTypingMetadata
	| SatisfactionSurveyMetadata
	| LiveChatInitMetaData
	| LastLiveChatMessageMetaData
	| AppointmentManagementMetaData
	| DoctorSearchMetaData
	| CustomMetadata
	| PostMessageMetadata;

export type MessageWithAppointmentSchedulingMetaData = Message & { metadata: AppointmentSchedulingMetaData };
export type MessageWithAppointmentSchedulingSummaryMetaData = Message & {
	metadata: AppointmentSchedulingSummaryMetaData;
};
export type MessageWithUserSurveyMetaData = Message & { metadata: UserSurveyMetaData };

export type MessageWithSatisfactionSurveyMetaData = Message & { metadata: SatisfactionSurveyMetadata };
export type MessageWithLiveChatGreetingMetadata = Message & { metadata: LiveChatInitMetaData };
export type MessageWithLastLiveChatMessageMetadata = Message & { metadata: LastLiveChatMessageMetaData };
export type MessageWithStartTypingTimeoutMetadata = Message & { metadata: LiveChatAgentStartTypingTimeoutMetadata };
export type MessageWithAppointmentManagementMetadata = Message & { metadata: AppointmentManagementMetaData };
export type MessageWithDoctorSearchMetadata = Message & { metadata: DoctorSearchMetaData };
export type MessageWithPostMessageMetadata = Message & { metadata: PostMessageMetadata };
export type MessageWithLLMResultMetadata = Message & { metadata: LLMResultMetaData };
export type MessageWithPasswordResetMetadata = Message & { metadata: PasswordResetMetaData };
type DeliveredStatus = 'delivered' | 'undelivered' | 'sending';
export interface MessageDeliveryStatus {
	messageId: string;
	status: DeliveredStatus;
	isLocked?: boolean;
}

export interface BasicMessage {
	text: string;
	incoming: boolean;
	isUndoable?: boolean;
	talkingtodoctor?: boolean;
	specialActions?: SpecialAction[];
	metadata?: MetaData;
	isInputHidden?: boolean;
	timeRemaining?: number;
	hasHelpfulnessSurvey?: boolean;
	isExternal?: boolean;
	timeStamp?: Date;
	messageId?: string;
	deliveredStatus?: DeliveredStatus;
	autocompleteUri?: string;
	nodeId?: string;
}

export interface TextMessage extends BasicMessage {
	type: 'text' | 'image' | 'video' | 'audio' | 'message' | 'command' | 'messageStatus';
	flowStep?: string;
	content: string;
	responseType?: ResponseType;
	autocomplete?: string;
	doctorId?: string;
	doctorInfo?: LiveChatDoctorInfo;
	messageStatus?: MessageDeliveryStatus;
}
export interface DeliveryStatusMessage extends TextMessage {
	type: 'messageStatus';
	messageStatus: MessageDeliveryStatus;
}

interface BasicQuickResponse {
	content: string;
	responseContext: string;
	evidenceExists: boolean;
	symptoms?: Record<string, unknown>;
}
export interface GenericQuickResponse {
	content: string;
	responseContext: string;
	evidenceExists: boolean;
	symptoms?: Record<string, unknown>;
	type: string;
	link?: string;
	buttonType?: string;
}

export interface QuickResponseButton extends BasicQuickResponse {
	type: 'text';
	buttonType?: 'button';
}

export interface QuickResponseCheckbox extends BasicQuickResponse {
	type: 'text';
	buttonType: 'multi' | 'exclusiveMulti';
}

export interface QuickResponseExternalLink extends BasicQuickResponse {
	type: 'webUrlExternalWithResponse' | 'webUrlExternal';
	buttonType?: 'button';
	link: string;
}
export interface QuickResponseLocation extends BasicQuickResponse {
	type: 'location';
	buttonType?: 'button';
}

export type QuickResponse =
	| QuickResponseButton
	| QuickResponseCheckbox
	| QuickResponseExternalLink
	| QuickResponseLocation;
export interface QuickResponseMessage extends BasicMessage {
	type: 'quickResponses';
	flowStep: string;
	responseType: ResponseType;
	responses: GenericQuickResponse[];
}

export interface CardResponse {
	content: string;
	responseContext: string;
	type: CardResponseType;
	link?: string;
}

export interface ProviderLocation {
	address: string;
	city: string;
	externalDepartmentId?: Nullable<string>;
	externalProviderId?: Nullable<string>;
	latitude: number;
	longitude: number;
	mapImage: string;
	name?: Nullable<string>;
	distance?: Nullable<number>;
	phone?: Nullable<string>;
	fax?: Nullable<string>;
	type: string;
	state?: Nullable<string>;
}
export interface ProviderReview {
	count: number;
	ratingAverage: number;
	ratingCount: number;
	ratingPercentageNormalize: number;
	url: string;
}
export interface ProviderAvailability {
	nextDate?: Nullable<string>;
	availableInNextDays?: Nullable<number>;
}

export interface VisitType {
	id: string;
	label?: string;
	name?: Nullable<string>;
	type: string;
}

export interface Provider {
	acceptingNewPatients: Nullable<boolean>;
	acceptingVirtualVisits: Nullable<boolean>;
	allowsOpenScheduling?: Nullable<boolean>;
	externalProviderId?: Nullable<string>;
	address?: Nullable<string>;
	addressPlaceholder: string;
	appointmentUrl?: Nullable<string>;
	availability?: Nullable<ProviderAvailability>;
	canBookOnline?: Nullable<boolean>;
	city?: Nullable<string>;
	fax?: Nullable<string>;
	clientOrganization?: Nullable<string>;
	id?: Nullable<number | string>;
	mapImage?: Nullable<string>;
	distance?: Nullable<number>;
	entityUrl: string;
	gender: Gender;
	imageUrl?: Nullable<string>;
	isPcp: boolean;
	canBookOnlineAuth?: Nullable<boolean>;
	latitude?: Nullable<number>;
	longitude?: Nullable<number>;
	name: string;
	phone?: Nullable<string>;
	phonePlaceholder: string;
	sourceId?: Nullable<string>;
	link?: Nullable<string>;
	type: 'provider';
	url: string;
	state?: Nullable<string>;
	visitTypes?: VisitType[];
	specialties: string[];
	languages: string[];
	locations: ProviderLocation[];
	reviews?: Nullable<ProviderReview>;
}

export interface ClinicCardData {
	address: string;
	addressPlaceholder?: Nullable<string>;
	clientOrganization?: Nullable<string>;
	disposition?: Nullable<string>;
	distance?: Nullable<number>;
	externalDepartmentId?: Nullable<number | string>;
	externalProviderId?: Nullable<number | string>;
	fax?: Nullable<string>;
	latitude: number;
	link?: Nullable<string>;
	city?: Nullable<string>;
	state?: Nullable<string>;
	longitude: number;
	mapImage: string;
	name: string;
	phone?: Nullable<string>;
	phonePlaceholder?: Nullable<string>;
	scheduleUrl?: Nullable<string>;
	schedulingType?: Nullable<string>;
	sourceId?: Nullable<string>;
	type: 'clinic';
	url?: Nullable<string>;
	entityUrl?: Nullable<string>;
	visitTypes?: VisitType[];
	waitingTime?: Nullable<number>;
	waitingTimeString?: Nullable<string>;
	waitingTimeTitleString?: Nullable<string>;

	operationHoursTitleString?: Nullable<string>;
	operationHoursString?: Nullable<string>;
	operationHoursUrl?: Nullable<string>;
	operationHoursUrlString?: Nullable<string>;
	nextAppointmentSlotString?: Nullable<string>;
	nextAppointmentSlotTitleString?: Nullable<string>;
	availabilityString?: Nullable<string>;
	availabilityTitleString?: Nullable<string>;
	openingHours?: Nullable<string>;
}

export type CardData = Provider | ClinicCardData | DiagnosisCardData;

export interface DiagnosisCardData {
	imageUrl: string;
	title: string;
	type: 'card';
	cardType: 'diagnosis';
}
export interface DiagnosisCard {
	type: 'card';
	nodeId: string;
	responses: CardResponse[];
	imageUrl: string;
	cardType: 'diagnosis';
	title: string;
	data: DiagnosisCardData;
}

export interface ClinicCard {
	type: 'clinic';
	title: string;
	nodeId: string;
	responses: CardResponse[];
	data: ClinicCardData;
}
export interface ProviderCard {
	type: 'provider';
	title: string;
	nodeId: string;
	imageUrl?: Nullable<string>;
	responses: CardResponse[];
	data: Provider;
}
export interface LocationCard {
	type: 'card';
	title: string;
	nodeId: 'location_confirm';
	imageUrl?: string;
	responses: CardResponse[];
}

export interface SimpleCard {
	type: 'card';
	title: string;
	subtitle?: string;
	nodeId: string;
	imageUrl?: string;
	responses: CardResponse[];
}

export interface PlacesCardData {
	name: string;
	phoneNumber?: string;
	address?: string;
	distance?: number;
	mapURL?: string;
	openingHours?: string;
}

export interface PlacesCard {
	type: 'places';
	title: string;
	nodeId: string;
	data: PlacesCardData;
	responses: CardResponse[];
}

export type Card = SimpleCard | LocationCard | ProviderCard | ClinicCard | DiagnosisCard | PlacesCard;

export interface CarouselMessage extends BasicMessage {
	type: 'carousel';
	flowStep: string;
	responseType?: ResponseType;
	cards: Card[];
}

export interface ErrorMessage {
	type: 'error';
	details: {
		code: string; // it's better to list possibile values
		message: string;
	};
}

export type Message = TextMessage | QuickResponseMessage | CarouselMessage;
export type MessageFromSocket = Message | ErrorMessage;
export type MultiSelectResponses = QuickResponseButton | QuickResponseCheckbox;
export type LocationResponses = QuickResponseLocation | QuickResponseButton;

export const isUIMessage = (message: MessageFromSocket): message is Message => UI_MESSAGE_TYPE.includes(message.type);
export const isErrorMessage = (message: MessageFromSocket): message is ErrorMessage => message.type === 'error';

export const isNotDeliveredStatusMessage = (message: Message): message is Message => message.type !== 'messageStatus';
export const isDeliveryStatusMessage = (message: Message): message is DeliveryStatusMessage =>
	message.type === 'messageStatus';
export const isTextMessage = (message: Message): message is TextMessage => message.type === 'text';

export const isQuickResponseMessage = (message: Message): message is QuickResponseMessage =>
	message.type === 'quickResponses';

export const isMultiSelectResponse = (response: GenericQuickResponse): response is MultiSelectResponses =>
	response.type === 'text';

export const isExternalResponse = (response: GenericQuickResponse): response is QuickResponseExternalLink =>
	response.type === 'webUrlExternalWithResponse' || response.type === 'webUrlExternal';

export const isMultiSelectResponses = (responses: GenericQuickResponse[]): responses is MultiSelectResponses[] =>
	responses.every(isMultiSelectResponse) && responses.some((response) => response.buttonType === 'multi');

export const isLocationResponses = (responses: GenericQuickResponse[]): responses is LocationResponses[] =>
	responses.some((response) => response.type === 'location');

export const isLocationCards = (cards: Card[]): cards is LocationCard[] =>
	cards.every((card) => card.nodeId === 'location_confirm') ||
	cards.every(
		(card) =>
			card.responses.length &&
			card.responses.every((response) => response.responseContext.includes('findingLocationCarousel')),
	);

export const isProviderCards = (cards: Card[]): cards is ProviderCard[] =>
	cards.every((card) => card.type === 'provider');

export const isClinicCards = (cards: Card[]): cards is ClinicCard[] => cards.every((card) => card.type === 'clinic');

export const isDiagnosisCards = (cards: Card[]): cards is DiagnosisCard[] =>
	cards.every((card) => 'cardType' in card && card.cardType === 'diagnosis');

export const isPlacesCards = (cards: Card[]): cards is PlacesCard[] => cards.every((card) => card.type === 'places');

export const isSimpleCards = (cards: Card[]): cards is SimpleCard[] =>
	cards.every((card) => card.type === 'card' && !('data' in card));

export const isMessageWithSchedulingMetadata = (
	message: Message,
): message is MessageWithAppointmentSchedulingMetaData | MessageWithAppointmentSchedulingSummaryMetaData =>
	message.metadata?.status === 'showAppointmentScheduling' || message.metadata?.status === 'showAppointmentSummary';

export const isMessageWithAppointmentSchedulingMetadata = (
	message: Message,
): message is MessageWithAppointmentSchedulingMetaData => message.metadata?.status === 'showAppointmentScheduling';

export const isMessageWithSatisfactionSurveyMetadata = (
	message: Message,
): message is MessageWithSatisfactionSurveyMetaData => message.metadata?.status === 'showSatisfactionSurvey';

export const isMessageWithLiveChatGreetingMetadata = (
	message: Message,
): message is MessageWithLiveChatGreetingMetadata => message.metadata?.status === 'showLiveChatInit';

export const isMessageWithAppointmentManagementMetadata = (
	message: Message,
): message is MessageWithAppointmentManagementMetadata => message.metadata?.status === 'showAppointmentManagement';

export const isMessageWithDoctorSearchMetadata = (message: Message): message is MessageWithDoctorSearchMetadata =>
	message.metadata?.status === 'showDoctorSearch' &&
	'data' in message.metadata &&
	'filters' in (message.metadata as DoctorSearchMetaData).data &&
	'sorting' in (message.metadata as DoctorSearchMetaData).data;

export const isMessageWithPasswordResetMetadata = (message: Message): message is MessageWithPasswordResetMetadata =>
	message.metadata?.status === 'showPasswordReset' &&
	'data' in message.metadata &&
	'constraintsMessage' in (message.metadata as PasswordResetMetaData).data &&
	'validations' in (message.metadata as PasswordResetMetaData).data;

export const isLastLiveChatMessageMetadata = (message: Message): message is MessageWithLastLiveChatMessageMetadata =>
	message.metadata?.status === 'lastLiveChatFeedbackMessage';

export const isMessageWithLiveChatAgentInfo = (message: Message): message is MessageWithDoctorInfo =>
	'doctorId' in message && !!message.doctorId && 'doctorInfo' in message && !!message.doctorInfo;

export const isMessageWithStartTypingTimeoutMetadata = (
	message: Message,
): message is MessageWithStartTypingTimeoutMetadata =>
	message.metadata?.status === 'startTyping' && 'data' in message.metadata && 'timeout' in message.metadata.data;

export const isSomeCustomUIComponentByResponseType = (responseType?: string): boolean =>
	RESPONSE_TYPES_CUSTOM_COMPONENTS.some((customUIComponentType) => customUIComponentType === responseType);

export const isMessageWithPostMessageMetadata = (message: Message): message is MessageWithPostMessageMetadata =>
	message.metadata?.status === 'postMessage';

export const isMessageWithLLMResultMetadata = (message: Message): message is MessageWithLLMResultMetadata =>
	message.metadata?.status === 'llmResult' &&
	'data' in message.metadata &&
	'searchResults' in (message.metadata as LLMResultMetaData).data &&
	'llmConversationId' in (message.metadata as LLMResultMetaData).data;

export const isParsedDataWithData = <T>(message: ParsedData<T>): message is NotNullableParsedData<T> =>
	message.data !== null;

export const getInputHiddenValue = (messages: Message[]): boolean =>
	pipe(
		messages,
		last,
		// This is a temporary fix to hide input when response type is email
		// It should be removed when the node with responseType=email will be updated with isInputHidden: true
		fold(constant(false), (m) => !!m.isInputHidden || m.responseType === 'email'),
	);

export const getInputHiddenValueFromHistory = (messages: Message[]): boolean => {
	const lastUserMessageIndex = lastIndexOf(messages, isUserMessage);
	const lastWidgetMessages = messages.slice(lastUserMessageIndex + 1);
	return getInputHiddenValue(lastWidgetMessages);
};

export const isEffectMessage = (message: Message | any): boolean =>
	!('content' in message) && !['quickResponses', 'carousel'].includes(message.type);
